import { useEffect, useState } from 'react';
import { ScreenSize } from 'glints-aries';

import { useMediaQuery } from './useMediaQuery';

export const regularScreenSizes = ['desktop', 'tablet', 'mobile'] as const;
export type RegularScreenSizes = (typeof regularScreenSizes)[number];

export const useIsMobile = (): boolean => {
  const isMobileScreen =
    useMediaQuery(`(max-width: ${ScreenSize.mobileL}px)`) ?? false;

  const [isMobile, setIsMobile] = useState(isMobileScreen);

  useEffect(() => {
    setIsMobile(isMobileScreen);
  }, [isMobileScreen]);

  return isMobile;
};

export const useGetRegularScreenSize = (): RegularScreenSizes => {
  const isMobileScreen = useMediaQuery(`(max-width: ${ScreenSize.mobileL}px)`);
  const isTabletScreen = useMediaQuery(`(max-width: ${ScreenSize.desktopS}px)`);

  if (isMobileScreen) {
    return regularScreenSizes[2];
  } else if (isTabletScreen) {
    return regularScreenSizes[1];
  }

  return regularScreenSizes[0];
};
